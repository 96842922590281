/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Mark";
  src: local("Mark"), url(./assets/fonts/Mark/MarkBook.otf) format("truetype");
}
* {
  font-family: "Mark";
}

.p-selectbutton .p-button.p-highlight {
  background: rgb(0, 141, 169);
  border-color: rgb(0, 141, 169);
  color: #fff;
}

.p-button {
  font-size: 0.9rem;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: rgba(0, 141, 169, 0.6);
  border-color: rgb(0, 141, 169);
  color: #222;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgb(0, 141, 169), 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px rgb(0, 141, 169);
  border-color: rgb(0, 141, 169);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: inset 0 0 0 0.15rem rgb(0, 141, 169);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: rgb(0, 141, 169);
  background: rgba(0, 141, 169, 0.1);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: rgb(0, 141, 169);
  background: rgb(0, 141, 169);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: rgba(0, 141, 169, 0.6);
  background: rgba(0, 141, 169, 0.6);
  color: #ffffff;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(0, 141, 169, 0.1);
  color: rgb(0, 141, 169);
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid rgb(0, 141, 169);
  outline-offset: 0.15rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: rgb(102, 17, 65);
  color: rgb(102, 17, 65);
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem white;
}

.p-treeselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 1px rgb(0, 141, 169);
  border-color: rgb(0, 141, 169);
}

.standard-button-within-form {
  float: right;
  margin: 40px 4px 4px !important;
}

.p-autocomplete-dropdown.p-button {
  background: rgb(0, 141, 169);
  border-color: rgb(0, 141, 169);
}

.country-icon {
  width: auto;
  height: 20px;
  margin-right: 5px;
}

.p-dialog .p-dialog-header {
  background-color: rgba(253, 185, 19, 0.8) !important;
  color: white !important;
}

.p-confirm-popup-accept.p-button {
  background-color: #f59e0b !important;
  border-color: #f59e0b !important;
  color: white !important;
}

.p-confirm-popup-reject.p-button.p-button-text {
  color: #222 !important;
}

.p-chip {
  padding: 0.75rem 1.5em !important;
  margin-right: 10px;
  margin-bottom: 10px;
}

.personal-contact .p-fieldset .p-fieldset-legend {
  background-color: transparent !important;
  border: 1px solid rgba(253, 185, 19, 0.8) !important;
}

.personal-contact .p-fieldset-toggleable .p-fieldset-legend a {
  /*color: rgb(102, 17, 65) !important;*/
}

.personal-contact .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem !important;
}

.office-tabs .p-tabview .p-tabview-panels {
  padding: 0;
}

.p-datatable .p-datatable-tbody > tr.role-director {
  background: rgba(0, 141, 169, 0.1) !important;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background: rgb(0, 141, 169) !important;
  color: white !important;
}

.autocomplete-full {
  width: 100%;
}

.autocomplete-full .p-autocomplete-multiple-container {
  width: 100%;
}

.section-container {
  padding: 20px 20px;
  /* background-color: #fcfaf5; */
  background-color: #fafafa;
  border-radius: 5px;
  margin: 0;
}

.p-chip {
  background-color: rgb(0, 141, 169) !important;
  color: white !important;
}

.no-shadow {
  box-shadow: none !important;
}

.country-dropdown .p-dropdown-items-wrapper {
  max-height: 100% !important;
}

.p-button.excel-button,
.p-button-raised.excel-button .p-splitbutton-defaultbutton,
.p-button-raised.excel-button .p-splitbutton-menubutton {
  background-color: rgb(118, 176, 67);
  border-color: rgb(118, 176, 67);
}

.p-button.excel-button:hover,
.p-button-raised.excel-button .p-splitbutton-defaultbutton:hover,
.p-button-raised.excel-button .p-splitbutton-menubutton:hover {
  background-color: rgb(88, 200, 30);
  border-color: rgb(88, 200, 30);
}

.office-excel-button .excel-button {
  translate: 0 50px;
  z-index: 10;
}

.offices .p-tabview .p-tabview-panels,
.professional-tabs .p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid rgba(102, 17, 65, 0.2);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: rgb(0, 141, 169);
  margin-right: 1px;
  color: rgb(0, 141, 169);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus {
  box-shadow: 0 0 0 1px rgb(0, 141, 169);
  border-color: rgb(0, 141, 169);
  color: rgb(0, 141, 169);
}

.advertiser-brands-chip .p-chip {
  width: 40%;
}